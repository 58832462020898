#header {
  width: 100%;
  padding-top: 4%;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header .brand-name {
  color: #46cd0c;
}

#header h2 {
  color: black;
  font-size: 18px;
}

.my-img-row {
  height: 200px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  color: #46cd0c;
  border: 2px solid #46cd0c;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #46cd0c;
  color: white;
}

#header .animated {
  animation: up-down 3s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.landing-card {
  width: 15vh !important;
  height: 100% !important;
  object-fit: cover;
}

.my-card {
  width: 21rem;
  border: 2px solid #46cd0c !important;
}

.my-spacer {
  background-color: #f1f1f1;
  /* #E0E0E0; */
}

.imgcontainer {
  position: relative;
  text-align: center;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgb(48, 86, 50);
  opacity: 0.83;
  color: white;
  padding: 5%;
  /* font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px; */
}

.imgcontainer .imgbtn:hover {
  background-color: black;
}

.rowStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

ul {
  list-style-type: none;
  list-style-image: url("../../assets/WasteIcon.png");
  list-style-position: inside;
  text-align: justify;
}

#theBtns {
  font-size: 1.4rem;
  margin-bottom: 3%;
}

#theBtns:hover {
  background: white;
  color: #46cd0c;
}

/* Visibility */
@media screen and (max-width: 47.75em) {
  .hide-for-mobile {
    /* hide for tablet and mobile */
    display: none;
  }
}

@media screen and (min-width: 47em) {
  .hide-for-laptops {
    /* hide for desktop viewports */
    display: none;
  }
}

@media screen and (max-width: 90.75em) {
  .hide-mission-mobile {
    /* hide for tablet and mobile */
    display: none;
  }
}
@media screen and (min-width: 90.4em) {
  .hide-mission-laptops {
    /* hide for desktop viewports */
    display: none;
  }
}
