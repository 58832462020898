@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: mulish;
}

::-webkit-scrollbar {
  display: none;
}

/** RESPONSIVE CODE 
===================================================================== */
@media (max-width: 991px) {
  #header {
    height: 80vh;
    text-align: center;
  }

  #header .header-img {
    text-align: center;
  }

  #header .header-img img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  #header {
    margin-top: 20px;
  }

  #header h1 {
    font-size: 28px;
  }

  #header h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  #header .header-img img {
    width: 60%;
  }
}
