.rowStyle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.image {
    margin:0 auto;
    max-width:100%;
    height:70vh;
    text-align:center;
} 

.thefacts {
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
}