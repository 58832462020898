@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
.column2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .cardStyle {
    margin-top: 1rem;
    margin-bottom:1rem;
    min-height: 5rem;
    max-width: 30rem;
}

.cardText {
  font-weight:bolder;
  /* padding-top: 5px; */
  font-size: 1.7rem;
  font-weight: 700;
  color: red;
}
.peopleIMG {
    margin:0 auto;
    max-width:100%;
    height:70vh;
}
.rowStyle {
    display: flex;
    justify-content:flex-start;
    align-items: center;
}

/* .rotatingText{
    position: absolute;
    right: 20px;
    left:8px;
    top: 0.5em;;
    opacity: 0;
    font-size: 1.7rem;
    font-weight:300;
}

.rotatingText:nth-of-type(1) {
    animation-name: rotate;
    animation-duration: 5s;
    animation-delay: .5s;
  }
  
  .rotatingText:nth-of-type(2) {
    animation-name: rotate-last;
    animation-duration: 5s;
    animation-delay: 5.1s;
    animation-fill-mode: forwards;
  }

  @keyframes rotate {
    0% {
      opacity: 0;
    }
    
    20%, 80% {
      opacity: 1;
    }
    
    100% {
      opacity: 0;
    }
  }

  @keyframes rotate-last {
    0% {
      opacity: 0;
    }
    20%, 80% {
        opacity: 1;
      }
    100% {
      opacity: 1;
    }
  } */